import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layouts/default'

const PageSuggestions = () => (
	<Layout>

		<div className="container-fluid ">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em', fontWeight: 200 }}>Suggest a Feature</h1>
			<div className="container">

				<div className="row">
					<div className="col-md-10 offset-1 mb-4">
						<div className="card card-feature">
							<div className="help-body" style={{padding: 40}}>

								<p className="pt-2" style={{fontWeight: 100, fontSize: 18}}>
									Feel like there's a glaring omission or a feature that would change your life with how you use Reldesk? Please let us know!
								</p>

								<p>
									We're always open to suggestions. We have a planned roadmap of improvements and features to Reldesk but we love hearing your feedback and suggestions.
									It also helps us prioritise our development. Your suggestion may be the casting vote that tips it into the next major (or minor) feature to be included.
								</p>

								<p>
									To make a suggestions, just send an email to <a href="support@reldesk.com?subject=Suggestion">support@reldesk.com</a> with the subject line '<strong>Suggestion</strong>' and we'll either open a dialog or add it to our internal tracking list.
								</p>

								<p className="text-center">
									<strong>Thanks!</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>


	</Layout>
)

export default PageSuggestions
